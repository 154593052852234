import React, { Fragment } from "react";
import './App.css';
// import { Header } from './components/header'
import { MainContent } from './components/content'

function App() {
  return (
    <Fragment>
      <div className="bg-imgFront bg-cover h-screen overflow-hidden">
        <div className="bg-[#282c34ec]/75 h-screen">
          {/* <Header /> */}
          <MainContent />
        </div>
      </div>
    </Fragment>

  );
}

export default App;
