import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


export function MainContent() {
    return <div className="text-white font-mono">
        <div className="flex flex-col items-center mx-10 md:mx-20 h-screen py-10">
            <div className="flex-row items-start w-full">
                <div className="bg-logoWhite bg-cover w-[75px] h-[50px] md:w-[110px] md:h-[70px]"></div>
            </div>
            <div className="absolute top-[33%]  md:top-2/4 md:left-2/4 md:-translate-y-1/2 md:-translate-x-1/2">
                <div className="flex flex-col justify-center items-center text-center">
                    <h1 className="text-3xl md:text-7xl font-bold">Sitio en construcción</h1>
                    <h4 className="text-base md:text-2xl">Estamos trabajando para pronto tenerlo en funcionamiento</h4>
                </div>
            </div>
            <div className="flex flex-col absolute bottom-[30%] md:bottom-[15%] left-2/4 -translate-y-2/4 -translate-x-2/4 text-center">
                <h4 className="text-2xl md:text-4xl font-bold">¿Necesitas contactarme?</h4>
                <div className="flex justify-center items-center mt-3">
                    <div className="flex flex-row justify-center items-center mr-3">
                        <FontAwesomeIcon icon={faLinkedin} size="lg" fixedWidth />
                        <a href="https://www.linkedin.com/in/samuelperezs/" target="_blank" rel="noreferrer" className="ml-2 italic">/samuelperezs</a>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                        <a href="mailto:contacto@samuelperez.cl" className="ml-2 italic">contacto@samuelperez.cl</a>
                    </div>
                </div>
            </div>


        </div>
        {/* <div className="bg-[#282c34]/75 h-[5vh] flex justify-center items-center italic">*282c34*
            contacto@samuelperez.cl
        </div> */}
    </div>
}